#app-pb {
  .bkPb-MuiGrid-spacing-xs-3 {
    width: calc(100% + 24px);
    margin: -12px!important;
  }
  .bkPb-MuiGrid-spacing-xs-3 > .bkPb-MuiGrid-item {
    padding: 12px;
  }
  .bkPb-MuiGrid-spacing-xs-2 > .bkPb-MuiGrid-item {
    padding: 8px;
  }
}
