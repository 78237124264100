#app-pb-surround {
  &.minWidth940 {
    .catalog .bkPb-MuiGrid-grid-sm-6,
    .not-building .bkPb-MuiGrid-grid-sm-6 {
      max-width: 33.3%;
      flex-basis: 33.3%;
    }

    .nav-head {
      border-bottom: 1px solid #eee;
    }

    .nav-part {
      position: absolute;
      top: 19px;
      right: 20px;
      width: 260px;
      border: 0;
      padding: 0;
    }

    .catalog .nav-part,
    .not-building .nav-part {
      width: 500px;
    }
  }

  &.minWidth1280 {
    .catalog .bkPb-MuiGrid-grid-sm-6,
    .not-building .bkPb-MuiGrid-grid-sm-6 {
      max-width: 25%;
      flex-basis: 25%;
    }
  }

  &.minWidth1920 {
    .catalog .bkPb-MuiGrid-grid-sm-6,
    .not-building .bkPb-MuiGrid-grid-sm-6 {
      max-width: 20%;
      flex-basis: 20%;
    }
  }

  &.maxWidth1775 {
    #app-pb {
      .react-pdf__Document,
      .react-pdf__Page__svg,
      .react-pdf__Page__svg > svg {
        width: 100% !important;
        height: auto !important;
      }
    }
  }

  &.minWidth940 {
    #app-pb {
      overflow: hidden;

      .page-render {
        position: unset;
        top: unset;
        padding-bottom: 0;
      }

      .app-overlay-bottom {
        position: absolute;
        z-index: 1;
        bottom: 12px;
        left: 30px;
        right: 30px;
        margin: 0 auto;
      }

      .app-header {
        position: relative;
        height: 60px;
        overflow: visible;
      }

      .inline-panes.panes-building {
        visibility: hidden;
        justify-content: center;

        .slick-slide {
          max-width: 320px;
        }

        .slick-track {
          > * {
            visibility: visible;
            transition:
              opacity 150ms linear 100ms,
              transform 150ms ease-in-out 100ms;
            will-change: opacity, transform;
          }

          &:hover > * {
            opacity: 0.6;
            transform: scale(0.94);
          }

          > *:hover {
            opacity: 1;
            transform: scale(1);
            transition-delay: 0ms, 0;
          }
        }
      }

      .catalog #app-nav-container,
      .not-building#app-nav-container {
        width: calc(100% - 50px);
        height: calc(100vh - 95px);
      }

      .intro:not(.intro-left) {
        width: 600px;
      }

      .nav-body .content {
        height: calc(100vh - 180px);
      }

      .relativeDrawerNav {
        width: calc(100vw - 200px - 51px);
      }

      #app-nav-container {
        position: absolute;
        top: 70px;
        right: 25px;
        left: 25px;
        margin: 0;
      }

      .is-building {
        #app-nav {
        }
      }

      .is-building#app-nav-container {
        left: unset;
      }

      .pane-build-section-title {
        flex-basis: 33.33%;
      }

      .pane-build-section-subtitle {
        display: flex;
      }

      .app-pane {
      }

      .three-container-inner {
        position: absolute;
        top: -50px;
        z-index: 0;
        height: 100%;
        overflow: hidden;
        width: 60%;
        left: -5%;
      }

      .app-pb:not(.overview) .three-container-inner {
        top: 0;
        left: 0;
      }

      a.toggle-href {
        &.selected {
          span {
            left: -10px;
          }
        }
      }
    }

    #app-pb.overview .three-container-inner {
      position: absolute;
      top: -50px;
      z-index: 0;
      width: 100%;
      height: calc(100% - 250px);
      overflow: hidden;
      left: auto;
    }
  }

  &.minWidth1200 {
    #app-nav-container {
      width: 650px;
    }

    .is-building {
      #app-nav {
        width: 650px;
      }
    }
  }

  &.minWidth940 {
  }

  &.minWidth1280 {
    #app-pb {
      .cta-action-bar {
        flex-direction: row;
        margin: 0 0 0 -10px;

        .cta-inline {
          flex-grow: 0;
          margin-bottom: 0;
        }

        .cta-inline:nth-child(2) {
          flex-grow: 1;
        }

        .cta-atc {
          flex-grow: 0;
        }

        .cta-rep {
          margin-left: -10px;
          margin-right: -10px;
          display: flex;
          flex-direction: column;
          align-self: stretch;
          justify-content: center;
          padding: 10px;
          font-weight: bold;
          font-size: 16px;
        }

        .cta-rep > a {
          width: 100%;
          display: block;
          padding: 10px 19px;
          text-align: left;
          font-weight: bold;
          font-size: 15px;
          color: rgba(0, 0, 0, 0.4);
          color: rgba(0, 0, 0, 0.4);
          position: relative;
          font-family: "Montserrat";
          text-transform: uppercase;
          font-size: 13px;
          margin-bottom: 5px;
        }
      }
    }
  }

  &.minWidth1500 {
    #app-pb {
      .app-pane.pane-stage {
        margin: 20px 15px;
      }

      .app-overlay-bottom {
        bottom: 50px;
      }
    }
  }

  &.minWidth1750 {
    #app-pb:not(.overview):not(.summary):not(.build) .three-container-inner {
      width: calc(100vw);
      left: calc(-750px);
      height: 100vh;
      top: 0;
      margin-left: 200px;
    }

    #app-pb {
      .the-form {
        #fieldsOptions {
          width: 50%;
          display: block;
          padding: 28px 0 0;
        }
      }
    }
  }

  &.minWidth840 {
    #app-pb {
    }
  }

  &.minWidth940 {
    #app-pb {
      .app-overlay-bottom {
        overflow: visible;
      }

      .app-pane.pane-stage {
        margin: 20px 15px;
      }

      &.build .three-container-inner,
      &.summary .three-container-inner {
        width: 100%;
        left: 0;
        right: 0;
        margin-left: 0;
        height: 480px;
        top: -20px;
        z-index: 2;
      }

      &.build.save .three-container-inner,
      &.summary.save .three-container-inner {
        height: 400px;
      }
    }
  }

  &.minWidth1750 {
    #app-pb {
      #app_pbQuantity input {
        width: 85px;
        font-size: 23px;
      }

      .atcPlaceholder {
        height: 120px;
      }

      .app-pane.pane-stage {
        min-height: 260px;
      }

      .mtXl15 {
        margin-top: 15px !important;
      }

      .app-overlay-bottom {
        max-width: 1600px;
        margin: 0 auto;
        bottom: 120px;
      }

      .intro:not(.intro-left) {
        h2 {
          font-size: 60px;
          margin: 20px 0;
        }

        h1 {
          font-size: 26px;
          margin: 0 0 60px;
        }
      }

      .intro-arrow {
        margin-bottom: 105px;
      }

      .is-building#app-nav-container {
        width: 750px;

        #app-nav,
        .pane-actions {
          width: 750px;
        }
      }
    }
  }

  &.minWidth1750 {
    #app-pb {
      &.summary {
        .container-fluid {
          max-width: 1770px;
          margin: 0 auto;
        }

        #appPdfViewer {
          .react-pdf__Page__svg {
            margin-left: auto;
            margin-right: auto;
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
          }

          #pdfNavigation {
            width: 856px;
          }
        }
      }
    }
  }

  &.maxWidth1280 {
    #app-pb {
      .cta-atc {
        #app_pbQuantity {
          input {
            width: 67px;
            font-size: 17px;
          }
        }

        .action {
          padding: 11px 53px;
          flex-grow: 1;

          .title {
            font-size: 17px;
          }

          .cta-accent {
            font-size: 10px;
          }
        }
      }

      #fieldsOptions {
        display: block;

        .form-field {
          margin-bottom: 20px;
        }
      }
    }
  }

  &.maxWidth1200:not(.maxWidth939) {
    #app-pb {
      .not-building#app-nav-container {
        right: 15px;
        left: 15px;
        width: calc(100% - 30px);
      }

      .is-building#app-nav-container {
        width: 50%;
        right: 15px;
        left: auto;
      }

      .grid-item-category .item-title,
      .nav-head h1,
      .relativeDrawerHeading h2 {
        font-size: 23px;
      }

      .app-overlay-bottom-scroll .intro h2 {
        font-size: 30px;
      }

      .exp-readmore {
        .bkPb-MuiExpansionPanelDetails-root {
          max-height: 50px;
        }

        .bkPb-MuiExpansionPanelSummary-content {
          margin-top: 9px;
        }

        .exp-readmore-title {
          font-size: 9px;
        }
      }

      .relativeDrawerHeading {
        padding-top: 0;
        padding-bottom: 5px;
      }

      .exp-readmore-content li,
      .exp-readmore-content p {
        font-size: 13px;
      }

      .nav-body {
        .content {
          height: calc(100vh - 170px);
        }
      }

      .is-building #app-nav {
        width: 100%;
      }

      .header-nav {
        margin-right: 15px;
      }

      .grid-item-image {
        padding: 25px 20px 50px;
      }

      .grid-palette-item .item-title {
        font-size: 10px;
      }

      .grid-item-detail .item-title {
        letter-spacing: 0;
      }

      .grid-item-category {
        padding: 0 0 40px;
      }

      .cta-cancel,
      .header-nav-action,
      .nav-body h3,
      .pane-build-section-title {
        font-size: 15px;
      }

      .pane-build-section-subtitle {
        font-size: 12px;
      }

      .cta-cancel,
      .header-nav-action {
        padding: 12px 25px;
        height: 40px;
      }

      .inline-panes {
        // transform: scale(.85);
      }
      // /* three items */
      .app-pane.pane-stage:first-of-type:nth-last-child(3),
      .app-pane.pane-stage:first-of-type:nth-last-child(3)
        ~ .app-pane.pane-stage {
        width: 33.33%;
      }
      /* four items */
      .app-pane.pane-stage:first-of-type:nth-last-child(4),
      .app-pane.pane-stage:first-of-type:nth-last-child(4)
        ~ .app-pane.pane-stage {
        width: 25%;
      }
      /* five items */
      .app-pane.pane-stage:first-of-type:nth-last-child(5),
      .app-pane.pane-stage:first-of-type:nth-last-child(5)
        ~ .app-pane.pane-stage {
        width: 20%;
      }

      .remove-selected {
        top: 20px;
      }
    }
  }

  &.maxWidth939 {
    #app-pb {
      min-height: 500px;
      padding-bottom: 15px;

      &.no-three {
        #app-nav-container {
          margin-top: 45px !important;
        }
      }

      &.summary {
        .app-overlay-bottom-scroll {
          margin: 0;
          padding: 30px 0 0;

          .intro {
            display: none;
          }

          .three-info-overlay + .cntnt {
            margin-top: 0;
          }
        }
      }

      .container-section {
        padding: 20px 0;
      }

      .container-fluid {
        padding-left: 25px;
        padding-right: 25px;
      }

      .row-6-4 {
        flex-direction: column;

        .col {
          width: 100%;
          padding: 0;
        }
      }

      .react-pdf__Document {
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
      }

      .react-pdf__Page {
        overflow: hidden;
      }

      .page-render {
        top: 100px;
        padding-bottom: 100px;
      }

      .bkPb-MuiSnackbar-root {
        position: fixed !important;
        bottom: 25px;
        left: 20px;
        font-size: 13px;
        top: unset;
        z-index: 999999999;
        right: 20px;
        display: flex;
        justify-content: center;
      }

      .bkPb-MuiSnackbarContent-root {
        #message-id {
          max-width: none;
        }
      }

      .app-overlay-bottom {
        padding-bottom: 50px;
      }

      .intro-arrow {
        padding-top: 140px;
        margin-bottom: 85px;
      }

      .app-header {
        overflow: visible;

        .header-nav {
          float: none;
          display: flex;
          position: relative;
          top: 50px;
          flex-grow: 1;
          flex-direction: row;
          margin: 35px 5px 0;

          > {
            .breadcrumb,
            .menu-holder {
              display: none;
            }
          }

          .header-nav-action {
            flex: 1;
            margin: 0 5px;

            &.cost {
              background: transparent;
              position: absolute;
              right: 0;
              padding: 25px 0 0;
              top: 30px;
              top: -87px;
              left: 0;
              justify-content: center;
              float: none;
            }

            &.back,
            &.save {
              border-radius: 50px;
              text-align: center;
              flex: 0 0 auto;
              margin: auto;
              box-shadow:
                0 2px 4px rgba(0, 0, 0, 0.1),
                0 10px 10px rgba(0, 0, 0, 0.1);
            }

            &.cart {
              position: absolute;
              top: -70px;
              border-radius: 50px;
              right: 0;
              height: 30px;
              box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
              transition: all ease 0.3s;
              background: #fff;
              color: #72bb71;
              letter-spacing: 0;
              padding: 0 8px;
              z-index: 99;

              &:before {
                display: none;
              }

              > .count {
                height: auto;
                background: none;
                font-size: 12px;
                padding: 0 8px 0 0;
              }

              > i {
                font-size: 20px !important;
                margin-right: 7px !important;
              }
            }
          }
        }
      }
    }

    #app-pb > {
      // .select2-container.select2-container--open {
      // 	top: 0 !important;
      // 	left: 0 !important;
      // 	right: 0 !important;
      // 	bottom: 0 !important;
      // 	width: 100% !important;
      // 	z-index: 999999 !important;
      // }
      //
      // .select2-container--open .select2-dropdown {
      // 	overflow: visible !important;
      // 	width: 100% !important;
      // 	top: 0 !important;
      //
      // 	.select2-results .select2-results__options[aria-expanded=true] {
      // 		max-height: none;
      // 	}
      // }
    }
  }

  &.maxWidth869 {
    #app-pb {
      .inline-panes {
        .slick-arrow {
          width: 230px;
        }
      }
    }
  }

  &.maxWidth839 {
    #app-pb {
      .three-container-inner {
        height: 450px;
        position: relative;
        top: 90px;
      }
    }
  }

  &.maxWidth767 {
    #app-pb {
      padding-bottom: 13px;

      .the-form {
        .form-field {
          float: none;
          width: 100%;
        }
      }

      .bkPb-MuiGrid-spacing-xs-2 {
        width: calc(100% + 16px);
        margin: -8px;
      }

      &.mode-light {
        .ccn-tab {
          &.bkPb-MuiButtonBase-root {
            min-height: 0;
            font-size: 12px;
            padding: 11px 15px;
            margin: 0 2px;
          }
        }
      }

      &.mode-dark {
        .ccn-tab {
          &.bkPb-MuiButtonBase-root {
          }
        }
      }

      .part-section {
        .part-selectors {
          width: 100%;
        }
      }

      .three-container-inner {
        height: 300px;
        position: relative;
        top: 120px;
      }

      .intro-arrow {
        padding: 25px 30px 0;
        margin: 0 auto 40px;

        h2 {
          font-size: 32px;
          margin: 0 auto 20px;
        }

        h1 {
          font-size: 17px;
          line-height: 24px;
        }

        &:after {
          margin: 10px 0 0;
          font-size: 40px;
        }

        &:before {
          display: none;
        }
      }

      .exp-readmore {
        margin-top: 0;

        .bkPb-MuiExpansionPanelSummary-content {
          margin-top: 14px;
        }

        .exp-readmore-content,
        .exp-readmore-content p {
          line-height: 17px;
          font-size: 13px;
        }

        .exp-readmore-title {
          font-size: 10px;
          letter-spacing: 0;
          text-transform: none;
          font-style: italic;
        }
      }

      .app-pane.pane-stage {
        margin: 15px;
      }

      .inline-panes {
        .slick-arrow {
          width: 170px;
        }
      }
    }
  }

  &.maxWidth639 {
    #app-pb {
      .inline-panes {
        .slick-arrow {
          width: 110px;
        }
      }

      .app-header {
        .header-nav {
          .header-nav-action {
            &.cost {
              top: 30px;
            }
          }
        }
      }
    }
  }

  &.maxWidth469 {
    #app-pb {
      .inline-panes {
        .slick-arrow {
          width: 66px;
        }
      }
    }
  }

  &.maxWidth399 {
    #app-pb {
      .app-pane.pane-stage {
        margin: 9px;
      }

      .inline-panes {
        .slick-arrow {
          width: 55px;
        }
      }
    }
  }
}
