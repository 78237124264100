#app-pb {
  line-height: 1;

  * {
    line-height: 1;
  }

  a,
  abbr,
  acronym,
  address,
  applet,
  article,
  aside,
  audio,
  b,
  big,
  blockquote,
  canvas,
  caption,
  center,
  cite,
  code,
  dd,
  del,
  details,
  dfn,
  div,
  dl,
  dt,
  em,
  embed,
  fieldset,
  figcaption,
  figure,
  footer,
  form,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  header,
  hgroup,
  i,
  iframe,
  img,
  ins,
  kbd,
  label,
  legend,
  li,
  mark,
  menu,
  nav,
  object,
  ol,
  output,
  p,
  pre,
  q,
  ruby,
  s,
  samp,
  section,
  small,
  span,
  strike,
  strong,
  sub,
  summary,
  sup,
  table,
  tbody,
  td,
  tfoot,
  th,
  thead,
  time,
  tr,
  tt,
  u,
  ul,
  var,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
  }

  a,
  abbr,
  acronym,
  address,
  applet,
  article,
  aside,
  audio,
  b,
  big,
  blockquote,
  canvas,
  caption,
  center,
  cite,
  code,
  dd,
  del,
  details,
  dfn,
  // div,
  dl,
  dt,
  em,
  embed,
  fieldset,
  figcaption,
  figure,
  footer,
  form,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  header,
  hgroup,
  i,
  iframe,
  img,
  ins,
  kbd,
  label,
  legend,
  li,
  mark,
  menu,
  nav,
  object,
  ol,
  output,
  p,
  pre,
  q,
  ruby,
  s,
  samp,
  section,
  small,
  span,
  strike,
  strong,
  sub,
  summary,
  sup,
  table,
  tbody,
  td,
  tfoot,
  th,
  thead,
  time,
  tr,
  tt,
  u,
  ul,
  var,
  video {
    // margin: 0;
    // padding: 0;
    // border: 0;
    // font-size: 100%;
    // vertical-align: baseline;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
  }


  article,
  aside {
    display: block;
  }

  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  ol,
  ul {
    list-style: none;
    line-height: 25px;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote {
    &:after,
    &:before {
      content: "";
      content: none;
    }
  }

  q {
    &:after,
    &:before {
      content: "";
      content: none;
    }
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  a {
    text-decoration: none;

    &.active,
    &:focus,
    &:hover {
      text-decoration: none;
    }
  }

  input,
  select,
  textarea {
    font-size: 16px;
    color: #555;
    border: 0;
    -webkit-transition: all 0.2s ease 0s;
    -moz-transition: all 0.2s ease 0s;
    -o-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
    float: none;
    display: block;
    margin: 0;
    background: none;
  }

  :focus {
    outline: 0;
  }

  input {
    &[type=email],
    &[type=number],
    &[type=password],
    &[type=tel],
    &[type=text] {
      height: 48px;
      width: 100%;
      padding: 4px 12px;
      border: 1px solid #C7C7C7;
      background-color: #FFF;
      color: rgba(51, 51, 51, 0.5);
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -ms-border-radius: 3px;
      border-radius: 3px;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      -ms-box-shadow: none;
      box-shadow: none;
    }
  }

  select,
  textarea {
    height: 48px;
    width: 100%;
    padding: 4px 12px;
    border: 1px solid #C7C7C7;
    background-color: #FFF;
    color: rgba(51, 51, 51, 0.5);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    box-shadow: none;
  }
}
