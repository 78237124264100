#app-pb.mode-light {
  background: radial-gradient(ellipse at center top, rgb(255, 254, 254) 0%, rgb(198, 202, 208) 52%, rgb(127, 134, 144) 100%) !important;

  > .fullscreen-enabled {
    background: radial-gradient(ellipse at center top, rgb(255, 254, 254) 0%, rgb(198, 202, 208) 52%, rgb(127, 134, 144) 100%) !important;
  }


  .toggle-href {
    background: #fff;
    color: rgba(0, 0, 0, 0.4);
  }

  a.toggle-href i:not(.las) {
    background: rgba(0, 0, 0, 0.2);
  }

  .header-nav .breadcrumb {
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.5);
    text-shadow: 0 -1px 0 rgba(255, 255, 255, 0.1);

    .accent {
      color: rgba(0, 0, 0, 0.4);
    }
  }

  .action-price {
    background: rgba(255, 255, 255, 0.4) !important;
    color: #888 !important;
  }

  .user-price .price-desc {
    color: #888 !important;
  }

  .pane-actions {
    box-shadow: 5px 20px 15px rgba(0, 0, 0, 0.1), 5px 0 6px rgba(0, 0, 0, 0.05) !important;

    .action {
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2);
    }
  }

  .action-price {
    box-shadow: -5px 20px 15px rgba(0, 0, 0, 0.05), -5px 0 6px rgba(0, 0, 0, 0.05) !important;
  }
  .ccn-tab {
    &.Mui-selected {
      background: #fff !important;
      color: rgba(0, 0, 0, 0.2) !important;
      box-shadow: 0 2px 1px rgba(0, 0, 0, 0.05), 0 5px 10px rgba(0, 0, 0, 0.05) !important;
      text-shadow: 0 -1px 0 rgba(255, 255, 255, 1) !important;
      border: 1px solid rgba(255, 255, 255, 0) !important;
    }

    &.bkPb-MuiButtonBase-root {
      text-shadow: none !important;
      border-radius: 50px !important;
      margin: 0 5px;
      font-family: "Raleway", "Open Sans" !important;
      text-transform: none;
      letter-spacing: 0;
      font-weight: 500;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.72) !important;
    }
  }

  .intro {
    h2 {
      color: #444;
      text-shadow: none;
      font-weight: 300;
    }

    h1 {
      color: #333;
      text-shadow: 0 2px 1px rgba(255, 255, 255, 0.1);
    }
  }

  .intro-arrow {
    &:before {
      border: 1px solid #555;
    }

    &:after {
      color: #555;
    }
  }

  .part-selectors select {
    text-shadow: none;
    width: 230px;
    background: #fff;
    font-family: "Raleway", "Open Sans";
  }

  .part-selectors .select2-container--default {
    text-shadow: none;
    width: 230px;
    background: #fff;
    font-family: "Raleway", "Open Sans";

    .select2-selection--single .select2-selection__rendered {
      font-weight: 500;
      letter-spacing: 0;
      font-size: 14px;
      text-transform: none;
    }
  }

  .nav-part .select2-container--default .select2-selection--single .select2-selection__rendered {
    font-weight: 500;
    letter-spacing: 0;
    font-size: 14px;
    text-transform: none;
  }

  .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #444 !important;
  }

  .nav-part .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #bbb !important;
  }

  .accent {
    font-weight: 500;
  }

  .nav-head h1 {
    font-weight: 300;
  }
  .nav-head h1 {
    letter-spacing: -1px;
  }
}
